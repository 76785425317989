import React, { useContext, useState, useEffect, useRef } from "react";

import Context from "../mainframe/Context";
import UseCaseFilter from "./UseCaseFilter/UseCaseFilter";
import { Collapse, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import UseCaseList from "./UseCaseList/UseCaseList";
import NotificationCenter, { getNotificationManager } from "../../util/notification/NotificationCenter";
import { copyToClipboard } from "../../util/Util";
import UseCaseValuesContainer from "./UseCaseValuesContainer";
import DetailContainer from "./DetailContainer";
import DeleteUseCaseModal from "../modals/DeleteUseCaseModal";
import UseCaseModal from "../modals/UseCaseModal";
import { updateUseCases, deleteUseCases } from "../elements/UseCase";

const getFilterFromSearchParams = (searchParams) => {
    let filter = {}
    for (const [key, value] of searchParams) {
        if (key !== "showDetail") filter[key] = value;
    }
    return filter
}

const getDetailFromSearchParams = (searchParams) => {
    let strUseCase = searchParams.get("showDetail")
    if (strUseCase) {
        let useCase = JSON.parse(strUseCase)
        return useCase
    }

    return null;

}

const UseCaseContainer = props => {

    let thisRef = useRef(null);


    let [context] = useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();

    const [notification, setNotifications] = useState([]);
    const [deleteUCOpen, setDeleteUCOpen] = useState();
    const [ucToDelete, setUCToDelete] = useState([]);

    const [editUCOpen, setEditUCOpen] = useState();
    const [ucToEdit, setUCToEdit] = useState([]);

    const [filter, setFilter] = useState(getFilterFromSearchParams(searchParams));
    const [detailUseCase, setDetailUseCase] = useState(getDetailFromSearchParams(searchParams));

    const [isFilterOpen, setIsFilterOpen] = useState(false);


    /**
     * Adjust the url
     */
    useEffect(() => {
        let queryString = Object.keys(filter).map(k => `${encodeURI(k)}=${encodeURI(filter[k])}`).join("&")

        let showDetail = detailUseCase ? JSON.stringify(detailUseCase) : false;
        if (queryString && showDetail) queryString += "&"
        if (showDetail) queryString += "showDetail=" + showDetail
        setSearchParams(queryString)

    }, [filter, detailUseCase])


    /**
     * Scrolleffect
     */
    useEffect(() => {
        if (thisRef) {

            const scrollListener = () => {
                let scrollableHeight = thisRef.current.parentElement.parentElement.scrollHeight - thisRef.current.parentElement.parentElement.clientHeight
                if (((scrollableHeight - thisRef.current.parentElement.parentElement.scrollTop) / scrollableHeight) < 0.5) {
                    if (!isFilterOpen) setIsFilterOpen(!isFilterOpen)

                } else {
                    if (isFilterOpen) setIsFilterOpen(!isFilterOpen)
                }
            }

            thisRef.current.parentElement.parentElement.addEventListener("scroll", scrollListener)

            return () => thisRef.current.parentElement.parentElement.removeEventListener("scroll", scrollListener)
        }

        return
    }, [isFilterOpen])

    const doSetFilter = (filter) => {
        setFilter({ ...filter })
    }

    const showDetail = (useCase) => {
        if (useCase) setDetailUseCase({ ...useCase })
        else setDetailUseCase(null)
    }


    /**
    * Add usesCases to the deletion list
    * @param {*} useCases 
    */
    const deletUseCases = (useCases) => {
        setUCToDelete(useCases);
        setDeleteUCOpen(true);
    }

    /**
     * Add usesCases to the deletion list
     * @param {*} useCases 
     */
    const editUseCases = (useCases) => {
        setUCToEdit(useCases);
        setEditUCOpen(true);
    }



    const notificationManager = getNotificationManager(notification, setNotifications)


    /**
     * Copy a UseCase to the Clipboard
     * @param {*} useCase 
     */
    const copyUseCaseDetails = async (useCase) => {
        let usc = { ...useCase }
        delete usc.id
        delete usc.uId
        delete usc.lastModification
        delete usc.firstObsTimestamp
        delete usc.lastObsTimestamp
        var text = JSON.stringify(usc)

        await copyToClipboard(text)
        let notification = notificationManager.createNotification("Kopiert", <span className="material-symbols-outlined color-secondary">check</span>, JSON.stringify(usc, null, 2))
        setTimeout(() => notificationManager.dismiss(notification), 6000)

    }

    return (<>
        <NotificationCenter
            notificationManager={notificationManager}
        />


        <div
            className={context.theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"}
            style={{ height: "400px", width: "100%", padding: "10px" }}>
            <UseCaseFilter filter={filter} setFilter={doSetFilter} notificationManager={notificationManager}></UseCaseFilter>
        </div >

        <div
            style={{ height: "10px", width: "100%", display: "flex" }}
            ref={thisRef}
        >   <span
            onClick={() => {
                if (isFilterOpen) thisRef.current.parentElement.parentElement.scrollTo(0, 0)
                else thisRef.current.parentElement.parentElement.scrollTo(0, 18999)
                setIsFilterOpen(!isFilterOpen)
            }}
            className="pointer"
            style={{ marginLeft: "auto", marginRight: "auto", width: "80%", marginTop: "-25px", height: "40px", display: "flex", alignItems: "center" }}>
                <span class="material-symbols-outlined" style={{ marginLeft: "auto", marginRight: "auto", transform: "scale(2, 1)" }}>
                    {isFilterOpen ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                </span>
            </span>

        </div >


        <div
            style={{ height: "calc( 98% - 15px)", width: "100%", padding: "0px 10px 10px 10px" }}
        >
            <UseCaseList
                filter={filter}
                editUseCases={(p) => editUseCases(p)}
                deleteUseCases={(p) => deletUseCases(p)}
                showUseCaseDetails={(p) => showDetail(p)}
                copyUseCaseDetails={copyUseCaseDetails}
            ></UseCaseList>

        </div>

        {
            detailUseCase ? <Offcanvas className={context.theme === "dark" ? "dark" : " light"}
                style={{ width: "40%" }}
                backdrop={false}
                direction="end"
                fade={false}
                isOpen={true}
                toggle={() => showDetail(null)}
            >
                <OffcanvasHeader toggle={() => showDetail(null)} style={{ color: "var(--color-on-surface)" }}>
                    Detailsicht
                </OffcanvasHeader>
                <OffcanvasBody>
                    <DetailContainer
                        useCase={detailUseCase}
                        selectionOptions={{}}
                        copyUseCaseDetails={copyUseCaseDetails}
                    >
                    </DetailContainer>

                </OffcanvasBody>
            </Offcanvas> : <></>
        }


        {/* Modals */}
        {
            deleteUCOpen ? <DeleteUseCaseModal
                isOpen={deleteUCOpen}
                setIsOpen={setDeleteUCOpen}
                useCases={ucToDelete}
                onSubmit={(useCases) => deleteUseCases(useCases, () => { doSetFilter(filter); setEditUCOpen(false) })}
            >
            </DeleteUseCaseModal> : <></>
        }
        {
            editUCOpen ? <UseCaseModal
                isOpen={editUCOpen}
                onClose={() => setEditUCOpen(false)}
                onSubmit={(useCases) => updateUseCases(useCases, () => { doSetFilter(filter); setEditUCOpen(false) })}
                useCases={ucToEdit}
                selectionOptions={{}}
                mode={"update"}>
            </UseCaseModal > : <></>
        }

    </>

    );
};

export default UseCaseContainer;
